@import "../../node_modules/@glidejs/glide/dist/css/glide.core.css";

.glide .glide__slide {
    padding: 45px 45px;
    position: relative;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background: #000;

        .cross:after, .cross:before {
            border-color: #fff !important;
        }
        .number, .date, .description {
            color: #fff;
        }
    }

    .cross:nth-child(1), .cross:nth-child(2) {
        position: absolute;
        width: calc(100% - 45px);
        height: calc(100% - 45px);
        top: 20px;
        left: 20px;
    }
    .cross:nth-child(1):after, .cross:nth-child(1):before, .cross:nth-child(2):after, .cross:nth-child(2):before {
        content: '';
        display: block;
        position: absolute;
        width: 32px;
        height: 20px;
    }
    .cross:nth-child(1):after {
        top: 0;
        left: 0;
        border-left: 2px solid $dark;
        border-top: 2px solid $dark;
    }
    .cross:nth-child(1):before {
        top: 0;
        right: 0;
        border-right: 2px solid $dark;
        border-top: 2px solid $dark;
    }
    .cross:nth-child(2):after {
        bottom: 0;
        left: 0;
        border-left: 2px solid $dark;
        border-bottom: 2px solid $dark;
    }
    .cross:nth-child(2):before {
        bottom: 0;
        right: 0;
        border-right: 2px solid $dark;
        border-bottom: 2px solid $dark;
    }

    .image {
        border-radius: 10px;
        margin-bottom: 20px;
    }
    .number {
        @extend .font-extra;
        font-size: rem(20);
        margin: 0;
        margin-bottom: 5px;

        span {
            font-size: rem(32);
        }
    }
    .date {
        @extend .font-extra;
        font-size: rem(24);
        margin-top: -10px;
    }
    .description {
        font-weight: bold;
        font-size: rem(18);
        margin-bottom: 0;
    }
}

.glide-simple {
    .glide__bullet {
        border: none;
        background: none;
        width: 22px;
        height: 9px;
        background: $dark;
        border-radius: 6px;
        margin-right: 15px;
        @include transition();

        &:hover {
            background: $danger;
        }
    }
    .glide__bullet--active {
        width: 84px;
        background-color: $danger;
    }
}