@font-face {
    font-family: 'Qargotesk 4F';
    src: url('./fonts/Qargotesk4F.woff2') format('woff2'),
    url('./fonts/Qargotesk4F.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// trzeba będzie to zaimportować lokalnie
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');

html {
    font-size: 16px;
}
body {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: $dark;
}

.fw-bold {
    font-weight: 400 !important;
}

.font-extra {
    font-family: 'Qargotesk 4F';
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
}
.font-extra:not(.btn):not(.number) {
    line-height: .9;
}

@include media-breakpoint-down('lg') {
    p {
        line-height: rem(29);
    }
}

@include media-breakpoint-down('lg') {
    .fs-4 {
        font-size: rem(18) !important;
    }
    .fs-3 {
        font-size: rem(18) !important;
    }
    .fs-2 {
        font-size: rem(32) !important;
        line-height: rem(40);
    }
}
.fs-5 {
    font-size: rem(18) !important;

    &:not(.btn) {
        line-height: rem(31);
    }
}

a:not(.glide__slide) {
    @include transition();
}
a:not(.glide__slide):hover {
    color: $danger;
}

.static-section-title .section-title{
    position: relative;
    z-index: 1;

    &:not(.static-no-rotate) {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100vh;
        padding: 11px 0;
        transform-origin: top left;
        transform: rotate(-90deg);
        text-align: center;
    }

    &.static-no-rotate {
        font-size: rem(24);
        position: absolute;
        top: 0;
        left: 25px;
        width: 40px;
        height: 100vh;
        display: flex;
        align-items: center;
    }
}
.modal .section-title {
    font-size: rem(32);

    @include media-breakpoint-up('lg') {
        font-size: rem(64);
    }
}
.section-title {
    font-size: rem(36);
    @extend .font-extra;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}