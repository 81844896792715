@function rem($target) {
    @return ($target / 16) * 1rem;
}

@mixin font($size, $weight: 'light') {
    font-size: rem($size);
    font-weight: map_get($fontWights, $weight);
}

@mixin grid-border() {
    *{
        box-shadow: 0px 0px 0px 1px rgba(red, .2);
    }
    .container{
        box-shadow: 0px 0px 0px 1px rgba(green, .4);
    }
    .row{
        box-shadow: 0px 0px 0px 1px rgba(blue, .2);
    }
    [class*="col"]:after {
        content: '';
        height: 100%;
        width: calc(100% - 30px);
        box-shadow: 0px 0px 0px 1px rgba(blue, .2);
        display: block;
        position: absolute;
        top: 0;
        left: 15px;
        pointer-events: none;
    }
}

//@mixin font-extra($size: false) {
//    @if($size) {
//        font-size: rem($size);
//    }
//    font-family: $font-extra;
//    font-weight: $font-extra-black;
//}

@mixin colors($colors) {
    @each $name, $color in $colors {
        .color-#{$name} {
            color: $color;
        }
        .bg-#{$name} {
            background-color: $color;
        }
    }
}
