
@include media-breakpoint-down('lg') {
    .fp-responsive .section,
    .fp-responsive .section .fp-slide,
    .fp-responsive .section .fp-tableCell {
        height: auto !important;
    }
}

.section:not(.section-footer) {
    margin-bottom: 20px;
    overflow: hidden;

    @include media-breakpoint-up('lg') {
        margin-bottom: 0;
    }
}

.section-who-are-we {
    .section-image {
        border-radius: 10px 0 0 10px;
    }
}

.section-startups, #logotypesModal, .section-partners {
    .logos {
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-up('xxl') {
            margin-left: -69px;
            margin-right: -69px;
        }
    }
    .logo {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;

        @include media-breakpoint-up('lg') {
            width: 12vw;
        }
    }
}

.section-footer {
    color: #fff;
    background: #E94641;
    position: relative;
    font-size: rem(14);
    padding-bottom: 25px;
    margin-top: 200px;

    //@include media-breakpoint-up('lg') {
    //    margin-top: 0;
    //}

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 200px;
        background-image: url("images/footer-bg.png");
        z-index: -1;
        top: -155px;

        @include media-breakpoint-up('lg') {
            top: unset;
            bottom: 164px;
        }

    }

    a {
        color: #fff;
        text-decoration: none;

        svg path {
            @include transition();
        }

        &:hover {
            color: $dark;

            svg path {
                fill: $dark;
            }
        }
    }

    @include media-breakpoint-down('lg') {
        .footer-info-line {
            overflow: hidden;
        }
        .social-media {
            position: relative;
            right: -15px;
        }
    }
    @include media-breakpoint-up('lg') {
        .footer-info-line {
            position: relative;
            right: -18px;
        }
    }
}