.page-rbl {
    .section-first {

        //@include media-breakpoint-down('lg') {
        //    h1 {
        //        margin-top: 30px;
        //    }
        //}

        h1 {
            position: relative;
            line-height: 1.4;
            margin-top: 120px;

            @include media-breakpoint-up('lg') {
                margin-left: 100px;
            }
        }
        .spacer {
            display: inline-block;
            width: 150px;

            @include media-breakpoint-up('lg') {
                width: 183px;
            }
        }
        .logo {
            @include media-breakpoint-down('lg') {
                position: absolute;
                z-index: -1;
                top: -10px;
                left: -20px;
                width: 184px;
            }
            @include media-breakpoint-up('lg') {
                position: absolute;
                z-index: -1;
                top: -102px;
                left: -156px;
            }
        }
    }

    .section-about-rbl {
        .arrow-down {
            position: absolute;
            right: -260px;
            top: 40px;
            z-index: -1;
        }
    }

    .box {
        position: relative;
        padding: 1rem 0;

        @include media-breakpoint-up('lg') {
            padding: 3rem;
        }

        @include media-breakpoint-down('lg') {
            p {
                font-size: rem(25) !important;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: -500px;
                width: 2000px;
                height: 100%;
                background: $dark;
                z-index: -1;
            }
        }
    }
}