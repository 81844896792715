.page-team {

    @include media-breakpoint-down('lg') {
        .fs-1 {
            font-size: rem(32) !important;
        }
    }

    @include media-breakpoint-up('lg') {
        .page-title {
            font-family: 'Qargotesk 4F';
            font-weight: normal;
            font-style: normal;
            text-transform: uppercase;
            font-size: rem(64);
            //@extend .font-extra;
            //textx-transform: uppercase;
            //@etend .fs-1;
        }
    }

    .section-first {
        @include media-breakpoint-down('lg') {
            h1 {
                font-size: rem(18) !important;
            }
            .fs-4 {
                font-size: rem(13) !important;
            }
        }
    }

    .arrow-section {
        .arrow {
            position: absolute;
            left: 50%;
            height: 186px;
        }
        picture:nth-child(1) .arrow {
            top: calc(200vh - 93px);
        }
        picture:nth-child(2) .arrow {
            top: calc(300vh - 93px);
        }
    }
}