
.wrapper {
    padding-top: 54px !important;
    padding-bottom: 50px !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up('lg') {
        padding-top: 124px !important;
        padding-bottom: 100px !important;
    }
}

//@media (max-height: 620px) {
//    .section  {
//        padding-top: 100px;
//    }
//}

.section:nth-child(1) .container {
    @include media-breakpoint-down('lg') {
        margin-top: 60px;
    }
}

.cross-st, .cross-et, .cross-sb, .cross-eb {
    position: fixed;
    z-index: 1000;
    @include transition();

    @include media-breakpoint-down('lg') {
        width: 20px;
    }
    &:not(.cross-et) {
        @include media-breakpoint-up('lg') {
            z-index: 9999;
        }
    }
}
.cross-st {
    top: 2px;
    left: 10px;
    z-index: 9999;
    display: none !important;

    @include media-breakpoint-up('lg') {
        top: 20px;
        left: 20px;
    }
}
.cross-et {
    top: 2px;
    right: 10px;
    display: none !important;

    @include media-breakpoint-up('lg') {
        top: 20px;
        right: 20px;
    }
}
.cross-sb {
    bottom: 20px;
    left: 20px;
    display: none;

    @include media-breakpoint-up('md') {
        display: block;
    }
}
.cross-eb {
    bottom: 20px;
    right: 20px;
    display: none;

    @include media-breakpoint-up('md') {
        display: block;
    }
}

.mb-6 {
    margin-bottom: 30px;
}
@include media-breakpoint-up('lg') {
    .mb-6 {
        margin-bottom: 130px;
    }
}

.pull-start {
    position: relative;

    @include media-breakpoint-down('md') {
        &.pull-start-xs {
            left: -15px;
        }
    }

    //@include media-breakpoint-up('sm') {
    //    left: calc( ((-100vw + 540px) / 2) - 15px );
    //}
    @include media-breakpoint-up('md') {
        left: calc( ((-100vw + 720px) / 2) - 15px );
    }
    @include media-breakpoint-up('lg') {
        left: calc( ((-100vw + 960px) / 2) - 15px );
    }
    @include media-breakpoint-up('xl') {
        left: calc( ((-100vw + 1140px) / 2) - 15px );
    }
    @include media-breakpoint-up('xxl') {
        left: calc( ((-100vw + 1320px) / 2) - 15px );
    }
}

.pull-end {
    position: relative;
    left: 0.75rem;

    @include media-breakpoint-up('sm') {
        left: calc( ((100vw - 540px) / 2) + 0.75rem );
    }
    @include media-breakpoint-up('md') {
        left: calc( ((100vw - 720px) / 2) + 15px );
    }
    @include media-breakpoint-up('lg') {
        left: calc( ((100vw - 960px) / 2) + 15px );
    }
    @include media-breakpoint-up('xl') {
        left: calc( ((100vw - 1140px) / 2) + 15px );
    }
    @include media-breakpoint-up('xxl') {
        left: calc( ((100vw - 1320px) / 2) + 15px );
    }
}
.highlighted{
    text-transform: uppercase;
    color:$danger;
    font-weight: 700;
}