.accordion-component {
    .btn {
        padding: 0;
        text-align: left;
        position: relative;
        padding-left: 30px;
        border:none!important;

        &:after, &:before {
            content: '';
            display: block;
            position: absolute;
            background: #000;
            @include transition();
        }
        &:after {
            width: 21px;
            height: 2px;
            top: 19px;
            left: 0;
        }
        &:before {
            width: 2px;
            height: 21px;
            top: 10px;
            left: 10px;
        }
        &:hover, &:focus{
            border:none!important;
        }
        @include media-breakpoint-up('lg') {
            &:after {
                top: 24px;
            }
            &:before {
                top: 15px;
            }
        }
    }

    .item {
        margin-bottom: 20px;
    }

    .item-content {
        display: none;
        padding-left: 31px;
    }
    .active {
        .item-content {
            display: block;
        }
        .btn {
            color: $danger;
            
            &:before {
                display: none;
            }
            &:after {
                background: $danger;
            }
        }
    }
}