
.form-group .error, .form-checkbox .error {
    display: none;
    color: $danger;
    font-size: rem(12);
}
.form-group.is-invalid, .form-checkbox.is-invalid {
    .error{
        display: flex;
        align-items: center;
    }
    input {
        border-color: $danger !important;
    }
    .custom-checkbox {
        border-color: $danger !important;
    }
}

.form-group {
    //box-shadow: 0px 0px 0px 1px rgba(blue, .2);
    position: relative;
    padding-top: 10px;
    margin-bottom: 20px;
}
.form-group label {
    position: absolute;
    top: 24px;
    left: 0;
    font-size: rem(14);
    pointer-events: none;
    @include transition();
    color: $secondary;

    @include media-breakpoint-up('md') {
        font-size: rem(16);
    }
}
input[type=text].form-control {
    padding: 15px 0;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $secondary;
    outline: none !important;
    box-shadow: none !important;
}
input[type=text].form-control:focus + label, input[type=text].form-control.is-touched + label {
    font-size: rem(12);
    top: 0;
}

.form-select {
    padding: 15px 0;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $secondary;
    outline: none !important;
    box-shadow: none !important;
}
.form-select:focus + label, .form-select.is-touched + label {
    font-size: rem(12);
    top: 0;
}

textarea.form-control {
    padding: 15px 0;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $secondary;
    outline: none !important;
    box-shadow: none !important;
}
textarea.form-control:focus + label, textarea.form-control.is-touched + label {
    font-size: rem(12);
    top: 0;
}
textarea + label {
    width: 100%;
    z-index: 2;
}
textarea + label .mask {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: #fff;
    z-index: -1;
}

.upload-area {
    border: 2px dashed $secondary;
    display: flex;
    align-items: center;
    color: $secondary;

    & > div {
        padding: 30px;
        width: 100%;
    }

    button {
        background: none;
        border: none;
        padding: 0;
    }
    p {
        margin: 0;
    }
    label {
        background: none;
        border: none;
        padding: 0;
        color: $dark;
        text-decoration: underline;
        @include transition();

        &:hover {
            color: $danger;
        }
    }

    .single-file:not(:nth-child(1)) {
        margin-top: 20px;
    }

    .if-drop {
        display: none;
    }

    &.drop {
        .if-drop {
            display: flex;
        }
        .not-drop {
            display: none !important;
        }
    }
}

.form-checkbox {
    position: relative;
    padding-left: 50px;

    input {
        position: absolute;
        left: -1000px;
    }
    label {
        position: relative;
        font-size: rem(16);
        font-weight: bold;
        cursor: pointer;

        .custom-checkbox {
            content: '';
            display: block;
            position: absolute;
            top: -2px;
            left: -50px;
            width: 30px;
            height: 30px;
            border: 1px solid $secondary;
        }
    }
    input:checked + label .custom-checkbox {
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-check' viewBox='0 0 16 16'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E%3C/svg%3E");
        background-size: 30px;
        background-position: center;
    }
}