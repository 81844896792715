
$light: #fff;
$dark: #000;
$danger: #F9423A;
$secondary: #878787;
//$primary: $danger;
$link-color: $dark;

$hoverSpeed: .4s;




$h1-font-size: rem(64);
$h2-font-size: rem(32);
$h3-font-size: rem(30);
$h4-font-size: rem(24);
$h5-font-size: rem(18);
//$h5-font-size: $font-size-base * 1.25;
//$h6-font-size: $font-size-base;

//@include grid-border();

//@include colors((
//    'primary': $color-primary,
//    'text': $color-text,
//    'light': #fff
//));


//$grid-breakpoints: (
//        xs: 0,
//        sm: 576px,
//        md: 768px,
//        lg: 992px,
//        xl: 1200px,
//        xxl: 1400px
//);

//$container-max-widths: (
//        sm: 540px,
//        md: 720px,
//        lg: 960px,
//        xl: 1140px,
//        xxl: 1320px
//);


// $spacer: 1rem !default;
// $spacers: () !default;
// $spacers: map-merge((
//         0: 0,
//         1: ($spacer * .25),
//         2: ($spacer * .5),
//         3: $spacer,
//         4: ($spacer * 1.5),
//         5: ($spacer * 3),
//         6: ($spacer * 5),
//         7: ($spacer * 10)
// ), $spacers);
