.section-tabs {
    .tabs-navigation {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: scroll;

        button:first-child {
            padding-left: 0;
        }
        button {
            white-space: nowrap;
            font-size: rem(19) !important;
        }
        button.active {
            color: $danger;
        }
    }

    @include media-breakpoint-down('lg') {
        .tabs-component {
            .item button {
                display: none;
            }
            .item p {
                display: none;
            }
            .item.active p {
                display: block;
            }
        }
    }

    @include media-breakpoint-up('lg') {
        .tabs-component {
            .item {
                margin-bottom: 30px;
            }

            .item .btn {
                text-align: left;
                position: relative;

                &:after {
                    content: '';
                    display: none;
                    position: absolute;
                    bottom: -60px;
                    left: 0;
                    width: 381px;
                    height: 80px;
                    background-image: url("images/arrow-tab.png");
                    background-repeat: no-repeat;
                    background-size: 100%;
                }
            }

            .item-1 {
                .btn {
                    padding-right: 100px;
                    &:after {
                        width: 251px;
                    }
                }
                .item-content {
                    margin-top: 40px;
                }
            }
            .item-2 {
                .btn {
                    padding-right: 80px;
                    &:after {
                        width: 380px;
                    }
                }
                .item-content {
                    margin-top: 30px;
                }
            }
            .item-3 {
                .btn {
                    padding-right: 90px;
                    &:after {
                        width: 520px;
                    }
                }
                .item-content {
                    margin-top: 30px;
                }
            }
            .item-4 {
                .btn {
                    padding-right: 60px;
                    &:after {
                        width: 530px;
                    }
                }
                .item-content {
                    margin-top: 30px;
                }
            }
            .item-5 {
                .btn {
                    padding-right: 100px;
                    &:after {
                        width: 225px;
                    }
                }
                .item-content {
                    margin-top: 30px;
                }
            }

            .item-content {
                margin-top: 30px;
            }
            .active .btn {
                font-size: rem(40) !important;

                &:after {
                    display: block;
                }
            }

            .item p {
                display: none;
            }
            .item.active p {
                display: block;
            }
        }
    }

    @include media-breakpoint-up('xl') {
        .tabs-component .active .btn {
            //font-size: rem(64) !important;
            font-size: rem(28) !important;
        }

        .tabs-component .item .btn {
            &:after {
                bottom: -70px;
            }
        }

        .tabs-component {
            height: 500px;
        }

        .tabs-component .item {
            .item-content {
                margin-top: 16px;
            }
        }

        .tabs-component {
            .item-1 {
                .btn {
                    padding-right: 100px;
                    &:after {
                        width: 167px;
                    }
                }
            }
            .item-2 {
                .btn {
                    padding-right: 40px;
                    &:after {
                        width: 227px;
                    }
                }
            }
            .item-3 {
                .btn {
                    padding-right: 60px;
                    &:after {
                        width: 316px;
                    }
                }
            }
            .item-4 {
                .btn {
                    padding-right: 30px;
                    &:after {
                        width: 344px;
                    }
                }
            }
            .item-5 {
                .btn {
                    padding-right: 100px;
                    &:after {
                        width: 151px;
                    }
                }
            }
        }
    }
}

.section-tabs-horizontal {
    .tabs-navigation {
        button {
            font-size: rem(19);
            padding: 0;

            @include media-breakpoint-up('md') {
                font-size: rem(24);
            }

            @include media-breakpoint-up('lg'){
                font-size: rem(36);
            }

            @include transition();
            &:hover {
                color: $danger;
            }
            &.active {
                color: $danger;
            }
        }
    }
    .tabs-component {
        position: relative;

        .item {
            pointer-events: none;
            @include transition();

            @include media-breakpoint-down('lg') {
                display: none;
            }

            @include media-breakpoint-up('lg') {
                position: absolute;
                top: 0;
                opacity: 0;
            }
        }
        .item.active {
            pointer-events: all;
            @include media-breakpoint-down('lg') {
                display: block;
            }

            @include media-breakpoint-up('lg') {
                opacity: 1;
            }
        }
    }
}