.page-accelerator .page-mask {
  img {
    position: absolute;
    z-index: -99;
  }
  picture:nth-child(1) img {
    top: calc(100vh + 260px);
    right: 0;
  }
  picture:nth-child(2) img {
    top: calc(300vh + 460px);
    right: 0;
  }
  picture:nth-child(3) img {
    top: calc(600vh + 160px);
    left: 0;
  }
  picture:nth-child(4) img {
    top: calc(600vh + 460px);
    right: 0;
  }
  picture:nth-child(5) img {
    top: calc(700vh + 160px);
    left: 0;
  }
}

.section-idea {
  img {
    border-radius: 10px;
  }
}

.section-timeline {
  .timeline {
    font-size: rem(24);

    @include media-breakpoint-down('lg') {
      .timeline-item:first-child {
        margin-top: 69px;
      }
      .timeline-item {
        margin-bottom: 85px;
      }
    }
    @include media-breakpoint-up('lg') {
      font-size: rem(20);
    }
    @include media-breakpoint-up('xl') {
      font-size: rem(24);
    }
  }
}

.section-mentors {
  position: relative;
  // z-index: 2;
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url('images/mask3.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    pointer-events: none;
    @include media-breakpoint-down('lg') {
      display: none;
    }
  }

  .person .info {
    font-size: rem(32);
    font-weight: bold;
    @extend .font-extra;
    line-height: rem(28);
    text-align: left;
    margin-bottom: 24px;
    margin-left: 10px;
  }
  .btn-outline-dark {
    font-size: rem(24) !important;
  }
  .person-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    @include media-breakpoint-down('lg') {
      overflow: scroll visible;
      padding-top: 23px;
    }
    @include media-breakpoint-up('lg') {
      flex-wrap: wrap;
    }
  }
  .person {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include media-breakpoint-down('lg') {
      width: 150px;
      flex: 0 0 150px;
      margin-right: 20px;
    }

    @include media-breakpoint-up('lg') {
      width: 20%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;
      cursor: pointer;
      padding: 0 10px;
    }

    &:hover {
      .person-dot {
        transform: scale(1.2);
        box-shadow: 0 0 10px 0 rgba(66, 68, 90, 0.5);
      }
      .person-name,
      .person-description {
        color: $danger;
      }
    }

    .person-dot {
      width: 104px;
      height: 104px;
      background: $danger;
      border-radius: 50%;
      margin-bottom: 38px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      @include transition();
    }
    .person-name {
      @extend .font-extra;
      font-size: rem(22);
      text-transform: uppercase;
      margin-bottom: 14px;
      @include transition();
    }
    .person-description {
      font-size: rem(13);
      font-weight: bold;
      @include transition();
    }
  }
}
.page-accelerator .section-mentors .person {
  &:last-of-type {
    align-items: flex-start;
  }
}
.page-accelerator {
  & .cross-st,
  & .cross-et,
  & .cross-sb,
  & .cross-eb {
    @include media-breakpoint-down('lg') {
      width: 11px;
      height: 11px;
    }
  }
  & .cross-st {
    top: 19px;
    left: 14px;
  }
  & .cross-et {
    right: 14px;
    top: 19px;
  }
  & .cross-sb {
    left: 14px;
  }
  & .cross-eb {
    right: 14px;
  }
  & .wrapper {
    .container {
      @include media-breakpoint-down('lg') {
        margin-top: 0;
      }
    }
    @include media-breakpoint-down('lg') {
      padding-top: 0px !important;
      padding-bottom: 0 !important;
    }
  }
  & .section-first {
    @include media-breakpoint-down('lg') {
      margin-bottom: 85px;
    }
  }
  .section-first {
    & .wrapper {
      @include media-breakpoint-down('lg') {
        padding-top: 35px !important;
      }
    }
  }
  & h2 {
    @include media-breakpoint-down('lg') {
      font-size: rem(32);
    }
  }
}
.page-accelerator.modal-open {
  & .cross-st,
  & .cross-sb,
  & .cross-eb {
    @include media-breakpoint-down('lg') {
      display: block !important;
      z-index: 9999999;
    }
  }
}


.page-accelerator .section-previous-edition {
  margin-bottom: 230px !important;
}

.page-accelerator .section-partners {
  .logo-wrapper {
    display: flex;
    @include media-breakpoint-down('lg') {
      overflow: scroll visible;
    }

    @include media-breakpoint-down('lg') {
      margin-top: 39px;
      margin-bottom: 83px;
    }
  }
  .logo {
    margin-right: 70px;
    flex-shrink: 0;
    max-width: 250px;
    @include media-breakpoint-up('lg') {
      margin-right: 4vw;
      max-width: 100%;
    }
  
  }
}
.page-accelerator {
  & .cross-st,
  & .cross-et {
    display: block !important;
  }

  & .section-partners {
    & .btn-xl {
      margin-top: 110px;

      @include media-breakpoint-down('lg') {
        margin-top: 60px;
      }
    }
  }
  & .section-first {
    & .main-title {
      font-weight: 700;
      margin-bottom: 25px;
      @include media-breakpoint-down('lg') {
        margin-top: 15px;
        margin-bottom: 30px;
      }
    }
  }
  .section-timeline {
    & h2 {
      margin-bottom: 93px;
      font-size: 36px;

      @include media-breakpoint-down('lg') {
        margin-bottom: 31px;
      }
    }
    & img {
      width: 100%;
    }
    & .timeline-subtitle {
      font-weight: 700;
    }
    & .timeline-col {
      &:nth-of-type(1),
      &:nth-of-type(5) {
        transform: translateX(5%);
      }
      &:nth-of-type(3),
      &:nth-of-type(7) {
        transform: translateX(-5%);
      }

      @media all and (max-width: 1400px) {
        transform: none !important;
      }
    }
    // & .wrapper {
    //   @include media-breakpoint-down('lg') {
    //     margin-bottom: 60px;
    //   }
    // }
  }
  & .section-lets-apply {
    & h2 {
      margin-bottom: 68px;

      @include media-breakpoint-down('lg') {
        margin-bottom: 55px;
      }
    }
    & .accordion-component .btn {
      margin-bottom: 24px;
      font-size: 34px !important;
    }
    & .accordion-component .item {
      margin-bottom: 6px;

      @include media-breakpoint-down('lg') {
        margin-bottom: 15px;
      }
    }
    @include media-breakpoint-down('lg') {
      margin-bottom: 15px;
    }
  }
  & .section-mentors {
    & h2 {
      margin-bottom: 82px;

      @include media-breakpoint-down('lg') {
        text-align: left !important;
        margin-bottom: 0;
      }
    }
    @include media-breakpoint-down('lg') {
      margin-bottom: 43px;
    }
  }
  & .section-partners {
  }
  & .section-challenges{
    & h2{
      margin-bottom: 80px;

      @include media-breakpoint-down('lg') {
        margin-bottom: 30px;
      }
    }
    & ul{
      list-style-type: none;
      padding-left: 0px;
      margin:30px 0;
      & li{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        &:before{
          content: '';
          display: block;
          width: 80px;
          height: 20px;
          background-image: url('images/arrow-right.png');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center right;
          flex-shrink: 0;
          margin-right: 10px;

          @include media-breakpoint-down('lg') {
            width: 50px;
          }
        }
      }
      
   
      
    }
  }
}
