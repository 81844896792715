body.page-form {

    .form-section {
        margin-top: 120px;
        margin-bottom: 160px;

        @include media-breakpoint-up('md') {
            margin-top: 180px;
            margin-bottom: 180px;
        }
    }

}