
.page-what-we-do .section-first {

    @include media-breakpoint-down('lg') {
        .fs-2 {
            font-size: rem(18) !important;
            line-height: rem(29);

            .font-extra {
                font-size: rem(20);
            }
        }

        img {
            width: 300px;
            max-width: 300px;
            position: relative;
            z-index: -1;
            left: -240px;
        }
    }
}

.page-what-we-do .page-mask {
    img {
        position: absolute;
        z-index: -99;
    }
    picture:nth-child(1) img {
        top: calc(100vh + 260px);
        right: 0;
    }
    picture:nth-child(2) img {
        top: calc(200vh + 160px);
        left: 0;
    }
    picture:nth-child(3) img {
        top: calc(400vh + 460px);
        left: -20px;
    }
    picture:nth-child(4) img {
        top: calc(600vh - 260px);
        right: 0;
    }
}

.recruiting-box {
    position: relative;

    @include media-breakpoint-down('lg') {
        padding-top: 70px;
        padding-bottom: 150px;
        margin-top: 20px;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-54%);
            left: -100px;
            width: 552px;
            height: 602px;
            background: url("images/recruiting-bg.png") no-repeat;
            background-size: 100% 100%;
            z-index: -1;

            @media(min-width: 470px) {
                width: calc(100% + 120px);
            }
            @include media-breakpoint-up('sm') {
                width: 702px;
                left: unset;
                right: -200px;
            }
        }
    }

    @include media-breakpoint-up('lg') {
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: -230px;
            left: -170px;
            width: 702px;
            height: 752px;
            background-image: url("images/recruiting-bg.png");
            z-index: -1;
        }
    }
}