body.nav-open {
  overflow: hidden !important;
}
.page-navigation {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: 0.5s top;
  display: flex;
  align-items: center;
  background: #fff;

  @include media-breakpoint-up('lg') {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .navigation-brand {
    padding-left: 40px;

    svg {
      width: 120px;

      @include media-breakpoint-up('md') {
        width: 200px;
      }
      @include media-breakpoint-up('lg') {
        width: 258px;
      }
    }

    svg path {
      @include transition();
    }
    &:hover {
      svg path {
        fill: $danger;
      }
    }
  }

  .navigation-toggle {
    display: block;
    border: none;
    padding: 0;
    background: none;
    margin-right: 40px;
    position: relative;
    z-index: 999;

    @include media-breakpoint-up('lg') {
      display: none;
    }
  }
  .navigation-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;

    @include media-breakpoint-down('lg') {
      display: none;
      background: #fff;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &.show {
      display: flex;
    }

    @include media-breakpoint-up('lg') {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-right: 40px;
    }
  }
  .navigation-nav-item {
    font-weight: bold;
    font-size: rem(18);

    a {
      padding: 15px 15px;
      @include media-breakpoint-up('lg') {
        padding: 5px 15px;
      }
      @include transition();
    }
    & .nav-link.nav-highlighted {
      position: relative;
      font-weight: 700;

      & .nav-highlighted__small-text {
        font-size: 14px;
        display: block;
        color: $danger;
      }

      // &:after {
      //   content: '';
      //   display: block;
      //   background: url('images/highlighted.png');
      //   position: absolute;
      //   bottom: 0;
      //   left: 50%;
      //   width: 100%;
      //   height: 22px;
      //   background-size: contain;
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   z-index: -1;
      //   transform: translate(-50%, 50%);
      // }
      @include media-breakpoint-down('lg') {
        text-align: center;
      }
    }
    a:hover {
      color: $danger;
    }
    @media all and (max-width: 1200px) and (min-width: 992px) {
      font-size: rem(16);
    }
  }

  @include media-breakpoint-up('lg') {
    .navigation-lang {
      position: absolute;
      top: -30px;
      right: 45px;
      text-decoration: none;
      a {
        text-decoration: none;
      }
    }
  }
}
