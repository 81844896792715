.modal {
    //width: 100%;

    .modal-dialog {
        //width: 100% !important;
        //max-width: 100% !important;
        //margin: 0;
    }
    .modal-content {
        border-radius: 0;
        border: none;
        //min-height: 100vh;

        @include media-breakpoint-up('lg') {
            padding: 40px;
        }
    }
    .modal-header {
        border: none;

        .btn svg {
            width: 35px;

            @include media-breakpoint-up('lg') {
                width: 70px;
            }
        }
        .btn svg line {
            @include transition();
        }
        .btn:hover svg line {
            fill: $danger;
            stroke: $danger;
        }
    }
}

#mentorsModal {
    .person .info {
      font-size: rem(32);
      font-weight: bold;
      @extend .font-extra;
      line-height: rem(28);
      text-align: left;
    }
    .person-dot{
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .person {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 89px;
      cursor: auto;
  
      @include media-breakpoint-up('lg') {
        padding-right: 50px;
      }
  
      .person-dot span {
        width: 88px;
        height: 88px;
        background: $danger;
        border-radius: 50%;
        display: block;
        @include transition();
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    
        margin-right: 27px;
        margin-left: auto;
        margin-right: auto;
  
        @include media-breakpoint-up('md') {
          width: 124px;
          height: 124px;
          margin-bottom: 20px;
        }
        @include media-breakpoint-up('lg') {
          margin-bottom: 20px;
        }
      }
      .person-name {
        @extend .font-extra;
        font-size: rem(22);
        text-transform: uppercase;
        margin-bottom: 14px;
        @include transition();
      }
      .person-description {
        font-size: rem(18);
        font-weight: bold;
        @include transition();
        @include media-breakpoint-up('lg') {
          font-size: rem(18);
        }
      }
    }
    & .section-title {
      margin-bottom: 35px;
    }
    & .person-links {
      margin-top: 42px;
    }
    .modal-full {
      min-width: 100vw;
      margin: 0;
    }
  
    .modal-full .modal-content {
      min-height: 100vh;
    }
    & .modal-header {
    //   padding: 0;
    //   padding-right: 19px;
      & button {
        padding: 0;
        border:none!important;
      }
    }
    & .modal-body {
      margin: 0;
    }
  }