.page-editions {
    .section-first {
        @include media-breakpoint-down('lg') {
            h1 {
                font-size: rem(18) !important;
            }
            .fs-4 {
                font-size: rem(13) !important;
            }
        }
    }

    .section-yt {
        @include media-breakpoint-down('md') {
            .arrow {
                position: relative;
                left: -15px;
            }
        }

        .yt-video {
            width: 275px;
            height: 150px;

            @include media-breakpoint-up('sm') {
                width: 400px;
                height: 200px;
            }
            @include media-breakpoint-up('lg') {
                width: 683px;
                height: 384px;
            }
        }
    }
}