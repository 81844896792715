.btn {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;

  &:hover, &-check:checked+.btn, &-check:active+.btn, &:active, &.active, &.show, &-check:focus+.btn, &:focus {
    border: none !important;
  }

  &.btn-md {
    font-size: rem(22);
    font-weight: normal;
  }
  @include media-breakpoint-down('lg') {
    &.btn-sm-mobile {
      font-size: rem(18) !important;

      span:nth-child(1):after,
      span:nth-child(1):before,
      span:nth-child(2):after,
      span:nth-child(2):before {
        width: 16px !important;
        height: 12px !important;
      }
    }
  }

  &.btn-outline-dark {
    position: relative;
    border: none;
    padding: 5px 34px;
    @include transition();
    @extend .font-extra;
    font-size: rem(36);

    span:after,
    span:before {
      @include transition();
    }

    &:hover {
      background: none;
      color: $danger;

      span:after,
      span:before {
        border-color: $danger !important;
      }
    }

    span:nth-child(1),
    span:nth-child(2) {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    span:nth-child(1):after,
    span:nth-child(1):before,
    span:nth-child(2):after,
    span:nth-child(2):before {
      content: '';
      display: block;
      position: absolute;
      width: 32px;
      height: 20px;
    }
    span:nth-child(1):after {
      top: 0;
      left: 0;
      border-left: 2px solid $dark;
      border-top: 2px solid $dark;
    }
    span:nth-child(1):before {
      top: 0;
      right: 0;
      border-right: 2px solid $dark;
      border-top: 2px solid $dark;
    }
    span:nth-child(2):after {
      bottom: 0;
      left: 0;
      border-left: 2px solid $dark;
      border-bottom: 2px solid $dark;
    }
    span:nth-child(2):before {
      bottom: 0;
      right: 0;
      border-right: 2px solid $dark;
      border-bottom: 2px solid $dark;
    }
  }
  &.btn-outline-dark.btn-sm,
  &.btn-outline-light.btn-sm {
    padding: 9px 15px;
    font-size: rem(16);

    span:nth-child(1):after,
    span:nth-child(1):before,
    span:nth-child(2):after,
    span:nth-child(2):before {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 13px;
    }
  }
  &.btn-outline-dark.btn-xl {
    padding: 0 30px;
    font-size: rem(60);

    span:nth-child(1):after,
    span:nth-child(1):before,
    span:nth-child(2):after,
    span:nth-child(2):before {
      content: '';
      display: block;
      position: absolute;
      width: 35px;
      height: 25px;
    }

    @include media-breakpoint-up('md') {
      padding: 0 60px;
      font-size: rem(100);
    }

    @include media-breakpoint-up('lg') {
      font-size: rem(144);

      span:nth-child(1):after,
      span:nth-child(1):before,
      span:nth-child(2):after,
      span:nth-child(2):before {
        width: 59px;
        height: 66px;
      }
    }
    @include media-breakpoint-down('lg') {
      font-size: rem(36);
    }
  }

  &.btn-outline-light {
    position: relative;
    border: none;
    padding: 5px 34px;
    @include transition();
    @extend .font-extra;
    font-size: rem(36);

    span:after,
    span:before {
      @include transition();
    }

    &:hover {
      background: none;
      color: $dark;

      span:after,
      span:before {
        border-color: $dark !important;
      }
    }

    span:nth-child(1),
    span:nth-child(2) {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    span:nth-child(1):after,
    span:nth-child(1):before,
    span:nth-child(2):after,
    span:nth-child(2):before {
      content: '';
      display: block;
      position: absolute;
      width: 32px;
      height: 20px;
    }
    span:nth-child(1):after {
      top: 0;
      left: 0;
      border-left: 2px solid $light;
      border-top: 2px solid $light;
    }
    span:nth-child(1):before {
      top: 0;
      right: 0;
      border-right: 2px solid $light;
      border-top: 2px solid $light;
    }
    span:nth-child(2):after {
      bottom: 0;
      left: 0;
      border-left: 2px solid $light;
      border-bottom: 2px solid $light;
    }
    span:nth-child(2):before {
      bottom: 0;
      right: 0;
      border-right: 2px solid $light;
      border-bottom: 2px solid $light;
    }
  }
}
.section-first {
  & .btn-outline-dark {
    margin-top: 34px;

    @include media-breakpoint-down('lg') {
      margin-top: 30px;
    }
  }
}
